/* You can add global styles to this file, and also import other style files */
* {
  transition: all 0.5s;
}

/* html {
  font-size: 15px !important;
} */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    white;
}

:root {
  --primaryColor: #203239;
  --secondaryColor: #e9dac1;
  --dbPrimaryColor: #17a2b8;
  --background: #33837e;
  --left-side: #5de2a3;
  --card: #c7ffbc;
  --card-line: #80ea69;
  --button-color-3: #26850e;
  --button-color-2: #379e1f;
  --button-color-1: #56be3e;
  --post: #dddde0;
  --numbers: #838183;
  --numbers-2: #aaa9ab;
  --post-line: #757375;
  --post-line2: #545354;
  --dollar: #4b953b;
  --background-dark: box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  --background-soft: box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --background-border-soft: box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --background-border: box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.button-primary-linear {
  outline: none !important;
  background: var(--primaryColor);
  background: linear-gradient(90deg,
      var(--primaryColor) 0%,
      var(--secondaryColor) 68%);
  font-weight: bold;
  border: none;
  box-shadow: none;
  height: 55px;
}

/* style */
.w-100 {
  width: 100% !important;
}

.border-b {
  border-bottom: 1px solid black;
}

.dp-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-gray {
  background-color: gray;
}

.wrong-input {
  border: 2px solid red;
}

/* button */
.button:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.button-primary-linear:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.buttonSubmit,
.buttonPrimary {
  width: 100%;
  outline: none !important;
  background: var(--primaryColor);
  background: linear-gradient(90deg,
      var(--primaryColor) 0%,
      var(--secondaryColor) 68%);
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  box-shadow: none;
  text-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  height: 55px;
}

.buttonSubmit:hover,
.buttonPrimary:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.button-success {
  outline: none !important;
  background: green;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: none;
  text-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  height: 55px;
  border-radius: 10px;
}

.button-error {
  border-radius: 10px;
  outline: none !important;
  background: red;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: none;
  text-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  height: 55px;
}

/* .breadcrumb */
.breadcrumbMain {
  display: flex;
  gap: 10px;
  padding-top: 10px;
  align-items: baseline;
  font-weight: 500;
}

.breadcrumbBack {
  background-color: var(--primaryColor);
  padding-right: 15px;
  border-radius: 20px 5px 5px 20px;
  color: white;
  width: 83px;
}

.breadcrumbBack:hover {
  cursor: pointer;
}

* {
  text-decoration: none !important;
}

.breadcrumbBack {
  background-color: var(--primaryColor);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-right: 15px;

}

.breadcrumbBack i {
  border-radius: 50%;
  color: var(--primaryColor);
  background-color: white;
  padding: 10px;
  font-size: 16px;
  margin-left: -5px;
  margin-right: 5px;
}


/* error */
#error li {
  list-style-type: none;
}

.error {
  color: red;
}

.error-detail {
  color: red;
}

.error-main ul {
  padding-left: 0 !important;
}

/* discount */
#discount {
  border-radius: 200px 0px 0px 200px;
  -webkit-border-radius: 200px 0px 0px 200px;
  -moz-border-radius: 200px 0px 0px 200px;
  width: 20% !important;
  background-color: red;
  color: white;
  position: absolute;
  right: 0;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.justify-center {
  display: flex;
  justify-content: center;
}

/* tooltip */
.tooltip-example {
  text-align: center;
  padding: 0 50px;
}

.tooltip-example [tooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
}

.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: #282a36;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}

.is-loading {
  height: 100%;
  width: 100%;
}

.is-loading .image,
.is-loading div,
.is-loading h2,
.is-loading p {
  background: #787676;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  -webkit-animation: 1.5s shine linear infinite;
  animation: 1.5s shine linear infinite;
}

.is-loading .image,
.is-loading div {
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.is-loading h2 {
  height: 30px;
}

.is-loading p {
  height: 70px;
}

@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}


@media screen and (min-width: 992px) {
  html {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 992px) {
  html {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 576px) {
  html {
    font-size: 22px !important;
  }
}


/* overlay */
#overlay {
  position: fixed;
  /* Sit on top of the page content */
  /* display: none; //Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}


.form-control {
  border: none !important;
  border-bottom: 1px solid #918f8f !important;
  border-radius: 0px !important;
}

.form-select {
  border: none !important;
  border-bottom: 1px solid #918f8f !important;
  border-radius: 0px !important;
}

.selected-dial-code {
  font-size: 20px;
}

div#toast-container {
  font-size: 13px;
}

.no-input-button::-webkit-inner-spin-button,
.no-input-button::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox için */
.no-input-button{
  -moz-appearance: textfield;
}